import React from 'react'
import Layout from '../../components/Demo/Layout'

const DemoPage = () => {
  const pageSlug = 'demo'
  return (
    <Layout pageSlug={pageSlug}>
      <div>My Code Samples</div>
    </Layout>
  )
}

export default DemoPage
